<template>
 <div id="about" class="about" v-title data-title="关于安冉云">
  <!-- 页面导航 -->
  <menu-components type="about"/>

  <!-- 页面主体 -->
  <main class="main">
   <!--banner-->
   <section class="home-banner">
    <el-carousel :autoplay="false" ref="carousel" :interval="5000" arrow="always" height="6.02rem">
     <el-carousel-item v-for="(item, index) in carouselList" :key="index">
      <div class="home-banner-img" :style="{backgroundImage: 'url('+ item.img + ')'}">
       <div class="container">
       <h2>{{item.txt}}</h2>
       <p style="margin-top:.2rem;margin-bottom:.5rem">{{item.txt4}}</p>
       </div>
      </div>
     </el-carousel-item>
    </el-carousel>
   </section>

   <!-- 公司介绍 -->
   <section class="about-1">
    <div class="container">
     <div class="plan-title ">
      <div>{{fuse1Details.title}}</div>
      <h2>{{fuse1Details.title1}}</h2>
     </div>
     <el-row :gutter="150"  class="plan-1-b" style="margin-top:0;margin-bottom:1rem;">
      <el-col :sm="24" :md="10">
      <div class="plan-1-b-r-d " style="height: 2.67rem;margin-left: 0;margin-top:0" v-if="fuse1Details.img">
       <img style="height: 2.69rem;" :src="fuse1Details.img" alt="">
      </div>
      </el-col>
      <el-col :sm="24" :md="13">
      <div class="plan-1-b-l">
       <h3 style="margin-top:.2rem;">河南安冉云网络科技有限公司</h3>
       <p>{{fuse1Details.txt}}</p>
       <p>{{fuse1Details.txt1}}</p>
      </div>
      </el-col>
     </el-row>
     </div>
   </section>

   <section class="about-2">
    <div class="container ">
     <div class="flex serve_list_box justify-between">
      <div v-for="(item, index) in serveList" style="border:none;width: 33%" :key="index">
       <div class="flex justify-center">
        <h2>
         <animate-number
             class="cph-font"
             from="1"
             :to="item.num"
             duration="2000"
             easing="easeOutQuad"
             :formatter="formatter"
         ></animate-number>
        </h2>
        <span>{{item.st}}</span>
       </div>
       <p>{{item.name}}</p>
      </div>
     </div>
    </div>
   </section>

  <!-- 发展历程-->
   <section class="about-3">
    <div class="container ">
     <div class="plan-1-b-l plan-title">
      <template>
       <div>{{about3Details.title}}</div>
       <h2>{{about3Details.title1}}</h2>
      </template>
     </div>
     <div class="about-3-b">
      <!--@beforeChange="beforeChange"-->
      <div class="about-3-b-1" tabindex="-1">
       <vue-slick-carousel v-bind="settings" class="vue-slick-carousel" ref="vueSlickCarousel" @afterChange="beforeChange">
        <div v-for="(item, index) in about3List" :key="index" @click="clickVueSlickCarousel(index)">
         <div class="about-3-b-1-i" :style="{marginTop: item.marginTop + 'rem'}" :class="about3Active == index ? 'active' : ''">
          <span></span>
          <div></div>
          <h2 class="cph-font">{{item.date}}</h2>
          <p>{{item.content}}</p>
         </div>
        </div>
       </vue-slick-carousel>
      </div>
      <div class="about-3-b-2">
        <!-- 时间轴 -->
        <vue-slick-carousel v-bind="settings1" class="vue-slick-carousel" ref="vueSlickCarouselDate">
         <div v-for="(item, index) in about3List" :key="index" @click="clickVueSlickCarousel(index)">
          <div class="about-3-b-2-i" :class="about3Active == index ? 'active' : ''">
           <h2 class="cph-font">{{item.date}}</h2>
          </div>
         </div>
        </vue-slick-carousel>
        <i class="about-3-b-2-l el-icon-arrow-left" @click="prevClick"></i>
        <i class="about-3-b-2-r el-icon-arrow-right" @click="nextClick"></i>
      </div>
     </div>
    </div>
    <div class="about-3-l"></div>
   </section>

  <!-- 证书-->
   <section class="about-4">
    <div class="container ">
     <div class="flex">
      <ul class="about-4-l">
       <li>
        <p>Honor Certificate</p>
        <h2>荣誉资质</h2>
       </li>
       <li class="serve_list_box">
        <div v-for="(item, index) in about4lList" style="border:none;width: 100%;" :key="index">
         <div class="flex justify-center">
          <h2>
           <animate-number
               class="cph-font"
               from="1"
               :to="item.num"
               duration="2000"
               easing="easeOutQuad"
               :formatter="formatter"
           ></animate-number>
          </h2>
          <span>{{item.st}}</span>
         </div>
         <p>{{item.name}}</p>
        </div>
       </li>
      </ul>
      <div class="about-4-r ">
       <vue-seamless-scroll :data="about4cList" class="seamless-warp">
        <div class="flex align-start">
         <!--<ul class="about-4-r-l">-->
         <!-- <li v-for="(item, index) in about4cList" :key="index">-->
         <!--  <img :src="item.img" alt="">-->
         <!-- </li>-->
         <!--</ul>-->
         <ul class="about-4-r-r flex wrap">
          <li v-for="(item, index) in about4rList" :key="index">
           <img :src="item.img" alt="">
          </li>
         </ul>
        </div>
       </vue-seamless-scroll>
      </div>
     </div>
    </div>
   </section>

  <!-- 联系我们 -->
   <section class="about-5">
    <div class="container">
     <div class="plan-1-b-l plan-title">
       <div>{{about5Details.title}}</div>
       <h2>{{about5Details.title1}}</h2>
     </div>
     <ul class="flex about-5-ul">
      <li v-for="(item, index) in about5List" :key="index" class="flex about-5-li">
       <div>
        <img :src="item.img" alt="">
       </div>
       <div>
        <h3>{{item.name}}</h3>
        <p class="cph-font" v-if="item.tel">{{item.tel}}</p>
        <el-button @click="jumpQr(item)" v-if="item.name == '市场合作'">扫码联系<i class="el-icon-arrow-right"></i></el-button>
        <el-button @click="jumpQr(item)" v-if="item.name == '人力资源'">扫码联系<i class="el-icon-arrow-right"></i></el-button>
        <el-button @click="jumpUrl" v-if="item.name == '投诉建议'">发起投诉<i class="el-icon-arrow-right"></i></el-button>
       </div>
      </li>
     </ul>
    </div>
    <div class="about-5-b flex align-center justify-center">
      <div class="flex align-end">
        地址：河南省郑州市金水区新科技市场5号楼5楼
      </div>
    </div>
   </section>
  </main>
  <!-- 页面底部 -->
  <footer-components style="margin-top:0;"/>
  <!-- 获取方案 -->
  <dialog-template v-if="dialogShow" :visible.sync="dialogShow" title="扫码联系" :subtitle="false" :img="about5DetailsImg"/>
 </div>
</template>

<script>
import MenuComponents from "@/components/Menu/index.vue";
import FooterComponents from "@/components/Footer/index.vue";
import Public from "@/assets/js/public";
import DialogTemplate from "@/components/Dialog/index.vue";

export default {
  name: 'About',
  components: {
   DialogTemplate,
   MenuComponents,
   FooterComponents
  },
  mixins: [],
  data () {
    return {
     // 轮播列表
     carouselList: [
        { img: require('@/assets/images/24.jpg'), txt: '用心做，好网络', txt4: '科技向善，为用户创造价值', jumpUrl: ''},
     ],
     // 当前轮播下标
     initialIndex: 0,
     // 当前方案概述详情
     fuse1Details: {
      title: 'Company Profile',
      title1: '公司简介',
      txt: '是一家提供多网融合运营服务的民营龙头科技型企业，同时持有工信部颁发的互联网接入及信息服务牌照，是政府认定的省级“专精特新“、“科技型雏鹰”、“AAA纳税”、“AAA信用”高新技术企业，以三大运营商、高校为主要服务客户。同时公司拥有网络运营、软件定制开发、5G云网融合及数字生态运营服务体系，并建成边缘计算相关省级工程技术研究中心及博士后创新实践基地。',
      img: require('@/assets/images/54.fw.png')
     },
     serveList: [
      {num: 60, st: '万+', name: '校园网用户覆盖'},
      {num: 11, st: '万+', name: '网元数量'},
      {num: 520, st: 'G', name: '互联网接入专线带宽'},
      {num: 2.5, st: '亿', name: '管理网络资产规模'},
     ],
     about3Details: {
      title: 'Development Path',
      title1: '发展历程',
     },
     fuse2List: [
      {img: require('@/assets/images/20.jpg'), title: '就业实习', txt:'Employment'},
      {img: require('@/assets/images/18.jpg'), title: '职业培训', txt:'Vocational Training'},
      {img: require('@/assets/images/21.jpg'), title: '文化研学', txt:'Cultural Studies'},
      {img: require('@/assets/images/19.jpg'), title: '商业文娱', txt:'Business and entertainment'},
     ],
     settings: {
      "dots": false, // 启用点指示器
      'arrows': false,// 展示箭头
      "infinite": true, // 是否无限循环展示内容
      "speed": 300,
      "slidesToShow": 4, // 同时显示的项目数量
      "slidesToScroll": 1, // 每次滚动的项目数量
      "initialSlide": 0
     },
     settings1: {
      "dots": false, // 启用点指示器
      'arrows': false,// 展示箭头
      "infinite": true, // 是否无限循环展示内容
      "speed": 300,
      "slidesToShow": 7, // 同时显示的项目数量
      "slidesToScroll": 1, // 每次滚动的项目数量
      "initialSlide": 0
     },
     goToIndex: 0,
     about3List: [
      {date: '2015', marginTop: '1.5', content: '公司成立，云认证平台上线，省内首个高校网络运营项目落地。完成院校服务合作5所，8万用户'},
      {date: '2016', marginTop: '1.3', content: '取得运营服务(ISP) 许可，开创“建、维、营“一体化服务模式。新增服务院校8所，6万用户'},
      {date: '2017', marginTop: '1.1', content: '实现省内首例多网融合统一运营院校项目落地，开创行业先河，高新技术企业、软件企业认定。新增服务院校2所，7万用户'},
      {date: '2018', marginTop: '.9', content: '用户规模突破25万，通过国家科技型中小企业、质量管理体系认定。新增服务院校2所，4万用户'},
      {date: '2019', marginTop: '.7', content: '用户规模突破40万，引入国家青千学者、1125聚才计划落地，市多网融合工程技术中心、CMMI3级，智慧校园平台研发落地。新增服务院校6所，15万用户'},
      {date: '2020', marginTop: '.5', content: '开创信息化管家服务模式，落地省多网融合工程技术中心，3大运营商DICT伙伴成功入围。新增服务院校1所，1万用户'},
      {date: '2021', marginTop: '.3', content: '博士后创新实践基地落地，郑州市园区多网融合智能应用工程研究中心“新网络信息运营服务管家“平台建设与示范应用。新增服务院校3所，3.6万用户'},
      {date: '2022', marginTop: '.2', content: '5G+智慧教育，高校场景5G专网用户服务，规模NO.1，与华为合作新功能：边界安全运行中心。新增院校2所 3万用户'},
      {date: '2023', marginTop: '0', content: '网络边界防护工程研究中心、信息技术服务标准（ITSS）3级认证 、售后服务能力五星认证，新增3校，4.3万用户'},
     ],
     about3Active: 0,
     about4lList: [
      {num: 39, st: '+', name: '行业相关资质'},
      {num: 56, st: '+', name: '社会各项荣誉'},
      {num: 80, st: '+', name: '软著专利'},
     ],
     about4cList: [
      {img: require('@/assets/images/zs/03.jpg')},
      {img: require('@/assets/images/zs/25.jpg')},
      {img: require('@/assets/images/zs/26.jpg')},
      {img: require('@/assets/images/zs/27.jpg')},
      {img: require('@/assets/images/zs/0369.jpg')},
      {img: require('@/assets/images/zs/698.jpg')},
      {img: require('@/assets/images/zs/03321.jpg')},
     ],
     about4rList: [
      {img: require('@/assets/images/zs/28.jpg')},
      {img: require('@/assets/images/zs/01.jpg')},
      {img: require('@/assets/images/zs/02.jpg')},
      {img: require('@/assets/images/zs/04.jpg')},
      {img: require('@/assets/images/zs/031.jpg')},
      // {img: require('@/assets/images/zs/29.jpg')},
      // {img: require('@/assets/images/zs/033.jpg')},
      {img: require('@/assets/images/zs/063.jpg')},
      // {img: require('@/assets/images/zs/066.jpg')},
      // {img: require('@/assets/images/zs/068.jpg')},
      // {img: require('@/assets/images/zs/03694.jpg')},
      // {img: require('@/assets/images/zs/036941.jpg')},
     ],
     about5Details: {
      title: 'Contact Us',
      title1: '联系我们',
     },
     about5List: [
      {img: require('@/assets/images/114.fw.png'), name: '市场合作', qr: require('@/assets/images/img9.jpg')},
      {img: require('@/assets/images/15.fw.png'), name: '人力资源', qr: require('@/assets/images/img14.jpg')},
      {img: require('@/assets/images/116.fw.png'), name: '客服帮助', tel: '0371-61235359'},
      {img: require('@/assets/images/17.fw.png'), name: '投诉建议'},
     ],
     about5DetailsImg: null,
     dialogShow: false
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {
   /* 切换 */
   beforeChange(val) {
    this.about3Active = val;
   },
   clickVueSlickCarousel (val) {
    this.about3Active = val;
    this.$refs.vueSlickCarousel.goTo(val)
   },
   prevClick() {
    this.$refs.vueSlickCarousel.prev()
    this.$refs.vueSlickCarouselDate.prev()
   },
   nextClick () {

    this.$refs.vueSlickCarousel.next()
    this.$refs.vueSlickCarouselDate.next()

   },
   formatter: function (num) {
    return Public.formatterNum(num)
   },
   /* 发起投诉 */
   jumpUrl () {
    window.open('https://dataex.autewifi.com/#/collect/4c11a69163744e238b33c11a243649ed')
     // window.location.href = 'https://dataex.autewifi.com/#/collect/4c11a69163744e238b33c11a243649ed'
   },
   /* 扫码 */
   jumpQr(item) {
    this.dialogShow = true;
    this.about5DetailsImg = item.qr
   }
  }
};
</script>
<style scoped  lang="scss">

</style>
<style>


</style>
